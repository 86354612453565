import {
  JoinWorkspacePaymentTypeEnum,
  SelectableAreasListAreaDivisionEnum,
  WorkspaceFeatureOptionTypeEnum
} from '@/api/openapi';
import { PLANNING_ROUTES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import { useUserInfoStore } from '@/store/userInfo';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

export const checkAuthAccess = async (
  route: RouteLocationNormalizedLoaded,
  router: Router
): Promise<boolean> => {
  const name = router.currentRoute.value.name?.toString() ?? '';
  const troNameList = [
    PLANNING_ROUTES.tro,
    PLANNING_ROUTES.index,
    PLANNING_ROUTES.create,
    PLANNING_ROUTES.edit,
    PLANNING_ROUTES.clone,
    PLANNING_ROUTES.result
  ];
  const isTroPage = troNameList.includes(name);
  const rsmNameList = [
    PLANNING_ROUTES.rsm,
    PLANNING_ROUTES.simulationCreate,
    PLANNING_ROUTES.simulationEdit,
    PLANNING_ROUTES.simulationClone,
    PLANNING_ROUTES.simulationResult
  ];
  const isRsmPage = rsmNameList.includes(name);

  const userInfoStore = useUserInfoStore();
  if (userInfoStore.joinableCompanies.length === 0) {
    await userInfoStore.fetchJoinableCompanies();
  }
  const notAccessMenu = type => {
    const status = userInfoStore.getFeatureOptionStatus(route, type);
    return !status;
  };
  const workspace = userInfoStore.currentWorkspaceFromRoute(route);
  const areaInfoStore = useAreaOptions();
  if (areaInfoStore.selectOptions.length === 0) {
    await areaInfoStore.fetch(route, undefined, undefined, false);
  }

  const statusBase =
    // 有料ワークスペースであること
    workspace?.paymentType === JoinWorkspacePaymentTypeEnum.Paid &&
    //   契約エリアに東名阪が含まれていること
    areaInfoStore.selectOptions.some(
      v => v.areaDivision === SelectableAreasListAreaDivisionEnum.Main
    );

  let status = false;

  if (isTroPage) {
    const troOption = workspace?.featureOptions.find(
      v => v.type === WorkspaceFeatureOptionTypeEnum.Tro
    );
    status =
      (statusBase &&
        // ワークスペースのTROオプションが契約中であること
        !notAccessMenu(WorkspaceFeatureOptionTypeEnum.Tro) &&
        // カンパニーのTROオプションが契約中であること
        (troOption?.companyIds?.includes(
          Number(route.params.companyId as string)
        ) ||
          troOption?.companyIds == null)) ??
      false;
  } else if (isRsmPage) {
    const rsmOption = workspace?.featureOptions.find(
      v => v.type === WorkspaceFeatureOptionTypeEnum.ReachSimulator
    );
    status =
      (statusBase &&
        // ワークスペースのリーチシミュレータオプションが契約中であること
        !notAccessMenu(WorkspaceFeatureOptionTypeEnum.ReachSimulator) &&
        // カンパニーのリーチシミュレータオプションが契約中であること
        (rsmOption?.companyIds?.includes(
          Number(route.params.companyId as string)
        ) ||
          rsmOption?.companyIds == null)) ??
      false;
  }

  return process.env.NODE_ENV !== 'local' && !status ? false : true;
};
